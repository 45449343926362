var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-iterator", {
    attrs: {
      "items-per-page": _vm.itemsPerPage,
      "footer-props": {
        itemsPerPageOptions: _vm.itemsPerPageOptions
      },
      items: _vm.filteredTables
    },
    on: {
      "update:itemsPerPage": function($event) {
        _vm.itemsPerPage = $event
      },
      "update:items-per-page": function($event) {
        _vm.itemsPerPage = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function() {
          return [
            _c(
              "v-row",
              { attrs: { "no-gutters": "", justify: "end" } },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "6" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: "Filter tables",
                        "append-icon": "search",
                        "single-line": "",
                        dense: ""
                      },
                      model: {
                        value: _vm.tableSearch,
                        callback: function($$v) {
                          _vm.tableSearch = $$v
                        },
                        expression: "tableSearch"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function(props) {
          return [
            _c(
              "v-list",
              { attrs: { "two-line": "" } },
              _vm._l(props.items, function(item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c(
                      "v-list-item",
                      { key: item.tid },
                      [
                        _c(
                          "v-list-item-avatar",
                          [
                            _c("v-icon", { attrs: { large: "" } }, [
                              _vm._v("mdi-table-large")
                            ])
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c(
                              "v-list-item-title",
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      value: _vm.tableActionsMenus[item.tid],
                                      left: "",
                                      origin: "center center",
                                      transition: "scale-transition",
                                      "max-width": "30%",
                                      "nudge-bottom": "30"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var menu = ref.on
                                            return [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var tooltip = ref.on
                                                          return [
                                                            _c(
                                                              "a",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "secondary--text subtitle-2"
                                                                },
                                                                Object.assign(
                                                                  {},
                                                                  tooltip,
                                                                  menu
                                                                )
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.short_id
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(item.short_id)
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      {
                                        staticStyle: { "overflow-y": "hidden" },
                                        attrs: { nav: "", dense: "" }
                                      },
                                      [
                                        _c("QueryBuilderDialog", {
                                          attrs: {
                                            tableId: parseInt(item.tid, 10),
                                            tableName: item.short_id,
                                            listItem: true
                                          },
                                          on: {
                                            querySubmitSuccess: function(
                                              $event
                                            ) {
                                              return _vm.$emit("switchTab", {
                                                tab: 5
                                              })
                                            },
                                            dialogClosed: function($event) {
                                              return _vm.setTableActionMenuValue(
                                                item.tid,
                                                false
                                              )
                                            },
                                            dialogOpened: function($event) {
                                              return _vm.setTableActionMenuValue(
                                                item.tid,
                                                true
                                              )
                                            }
                                          }
                                        }),
                                        _c(
                                          "v-list-item",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.exploreTable(
                                                  item.short_id,
                                                  item.tid,
                                                  "columnList"
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-list-item-title",
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-1",
                                                    attrs: { small: "" }
                                                  },
                                                  [_vm._v("zoom_in")]
                                                ),
                                                _vm._v(" Explore columns ")
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.exploreTable(
                                                  item.short_id,
                                                  item.tid,
                                                  "dataPreview"
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-list-item-title",
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-1",
                                                    attrs: { small: "" }
                                                  },
                                                  [_vm._v("mdi-playlist-play")]
                                                ),
                                                _vm._v(" Preview table ")
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c("CopyToClipboard", {
                                          attrs: {
                                            textToCopy: _vm.quotedName(
                                              item.short_id
                                            ),
                                            buttonName:
                                              "Copy name to clipboard",
                                            type: "listItem"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-subtitle",
                              { staticClass: "text--primary" },
                              [
                                _c("div", { staticClass: "d-flex caption" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-bold primary--text mr-1"
                                    },
                                    [_vm._v("Size:")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.humanFileSize(item.bytes, true)
                                      ) +
                                      " "
                                  ),
                                  _c("span", { staticClass: "mx-2" }, [
                                    _vm._v("|")
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-bold primary--text mr-1"
                                    },
                                    [_vm._v("Rows: ")]
                                  ),
                                  _c("span", { staticClass: "caption" }, [
                                    _vm._v(
                                      _vm._s(
                                        item.row_count
                                          .toString()
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                      )
                                    )
                                  ])
                                ])
                              ]
                            ),
                            _c(
                              "v-list-item-subtitle",
                              [
                                item.description
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c("span", _vm._g({}, on), [
                                                    _vm._v(
                                                      _vm._s(item.description)
                                                    )
                                                  ])
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(item.description))
                                        ])
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v("No description available.")
                                    ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("v-list-item-action", [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c("QueryBuilderDialog", {
                                attrs: {
                                  tableId: parseInt(item.tid, 10),
                                  tableName: item.short_id
                                },
                                on: {
                                  querySubmitSuccess: function($event) {
                                    return _vm.$emit("switchTab", { tab: 5 })
                                  }
                                }
                              }),
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    left: "",
                                    "offset-x": ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    text: "",
                                                    color: "primary",
                                                    small: ""
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("more_horiz")
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c("v-card-text", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-start flex-column"
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "white--text",
                                                attrs: {
                                                  color: "primary",
                                                  text: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.addToStaging([
                                                      item
                                                    ])
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-1",
                                                    attrs: { small: "" }
                                                  },
                                                  [_vm._v("share")]
                                                ),
                                                _vm._v("stage ")
                                              ],
                                              1
                                            ),
                                            _vm.isDevelopment === true
                                              ? _c("RenameButton", {
                                                  attrs: {
                                                    selected: [item],
                                                    objectType: "table",
                                                    dispatchFunction: function() {
                                                      _vm.$store.dispatch(
                                                        "snapshotStore/fetchCurrentTables",
                                                        _vm.$route.params.snid
                                                      )
                                                    }
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm.isDevelopment === true
                                              ? _c("DeleteButton", {
                                                  attrs: {
                                                    selected: [item],
                                                    objectType: "tables",
                                                    dispatchFunction: function() {
                                                      _vm.$store.dispatch(
                                                        "snapshotStore/fetchCurrentTables",
                                                        _vm.$route.params.snid
                                                      )
                                                    }
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm.isDevelopment === true
                                              ? _c("EditDescriptionButton", {
                                                  attrs: {
                                                    selected: [item],
                                                    objectType: "table",
                                                    dispatchFunction: function() {
                                                      _vm.$store.dispatch(
                                                        "snapshotStore/fetchCurrentTables",
                                                        _vm.$route.params.snid
                                                      )
                                                    }
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    ),
                    _c("v-divider", { key: index, attrs: { inset: "" } })
                  ],
                  1
                )
              }),
              0
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }